/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import dotYellow from '../assets/dot-yellow.png';
import dotBlue from '../assets/dot-light-blue.png';
import dotGreen from '../assets/dot-green.svg';
import dotGreenDark from '../assets/dot-dark-green.png';
import dotGreenGradient from '../assets/dot-gradient-green.png';
import ringYellow from '../assets/ring-yellow.png';
import ringBlue from '../assets/ring-light-blue.png';
import ringGreen from '../assets/ring-green.png';
import ringGradienGreeen from '../assets/ring-gradient-green.png';

function Dots() {
  const images = [dotYellow, dotBlue, dotBlue, dotGreen, dotGreenDark, dotGreenGradient, ringYellow, ringBlue, ringGreen, ringGradienGreeen]
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const dotsArray = useMemo(() => {
    return Array.from({ length: 36 }, (_, index) => index).map(item => ({
      left: Math.floor(Math.random() * (windowWidth - 10)),
      top: Math.floor(Math.random() * (windowHeight - 20)),
      width: Math.random() * 40,
      imageIndex: Math.floor(Math.random() * images.length)
    }));
  }, []);

  return (
    <div className="DotsContainer">
      {dotsArray.map((item, index) => (
        <span
          key={index}
          className={`IntroDot${index + 1}`}
          style={{ left: item.left + 'px', top: item.top + 'px', width: item.width }}>
          <img alt={'Colored dot moving' + (index + 1)} src={images[item.imageIndex]} />
        </span>
      ))}
    </div>
  );
}

export default Dots;
