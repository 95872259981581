import imgBanner from '../assets/banner 4.png';

function Banner() {
    return (
        <div className="Banner">
            <img src={imgBanner} alt="Game characters composition"/>
        </div>
    );
}

export default Banner;
