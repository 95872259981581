
function Heading() {
    return (
        <div className="Heading">
            <h1>EXPAND YOUR LOBBY WITH<br /><p className="AnimatedText">
                <span className="AnimatedLetter1">A</span>
                <span className="AnimatedLetter2">R</span>
                <span className="AnimatedLetter3">C</span>
                <span className="AnimatedLetter4">A</span>
                <span className="AnimatedLetter5">D</span>
                <span className="AnimatedLetter5">E</span> 
                &nbsp;
                <span className="AnimatedLetter7">G</span>
                <span className="AnimatedLetter8">A</span>
                <span className="AnimatedLetter9">M</span>
                <span className="AnimatedLetter10">E</span>
                <span className="AnimatedLetter11">S</span>
            </p> <br />THAT BANG<span className="exclamation">!</span></h1>
        </div>
    );
}

export default Heading;
