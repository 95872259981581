/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie'
import imgIcon from '../assets/datepicker-icon.png';
import imgAirplane from '../assets/airplane-1.png';
import imgLines from '../assets/lines.png';
import { WORKER_URL } from '../config';
import * as Sentry from "@sentry/react";

const REQUEST_STATUS = {
  INITIAL: 0,
  LOADING: 1,
  SUCCESS: 2
}

function BookingForm(props) {
  const didBook = Cookies.get('did_book') === 'true';
  const [dateIndex, setDateIndex] = useState(null);
  const [timeslotId, setTimeslotId] = useState(null);
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [requestStatus, setRequestStatus] = useState(didBook ? REQUEST_STATUS.SUCCESS : REQUEST_STATUS.INITIAL);
  const [error, setError] = useState('');
  const [timeslotError, setTimeslotError] = useState('');
  const [focus, setFocus] = useState({
    name: false,
    company: false,
    email: false,
    message: false
  })
  useEffect(() => {
    if (error.length > 0) {
      const element = document.getElementById('error-message');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [error])
  useEffect(() => {
    if (props.timeslots.length > 0 && timeslotId === null) {
      const timeslot = props.timeslots.find(item => item.remainingBookings > 0);
      if (timeslot) {
        setTimeslotId(timeslot.id);
        setDateIndex(timeslot.dayGroup - 1);
      } else {
        setTimeslotError('All time slots have been booked');
      }
    }
  }, [props.timeslots.length])
  function onFocus(id, shouldFocus) {
    setFocus((prevSate) => {
      return {
        ...prevSate,
        [id]: true
      }
    });
    if (shouldFocus) {
      const input = document.getElementById(id);
      if (input) {
        input.focus();
      }
    }
    if (error) {
      setError('')
    }
  }
  function onBlur(id) {
    setFocus((prevSate) => {
      return {
        ...prevSate,
        [id]: false
      }
    })
  }
  function onSelectDay(event) {
    if (error) {
      setError('')
    }
    if (timeslotError) {
      setTimeslotError('')
    }
    setDateIndex(+event.target.value);
    const timeslot = props.timeslots.find(item => item.dayGroup - 1 === (+event.target.value) && item.remainingBookings > 0);
    if (timeslot) {
      setTimeslotId(timeslot.id);
    } else {
      setTimeslotError('No time slots available for selected date')
    }

  }
  function onSelectTime(event) {
    setTimeslotId(+event.target.value);
    if (error) {
      setError('')
    }
    if (timeslotError) {
      setTimeslotError('')
    }
  }
  const handleSubmit = () => {
    if (!name || !company || !email) {
      setError('Form is missing information. Fill out the form correctly');
      return;
    }
    setRequestStatus(REQUEST_STATUS.LOADING);
    async function sendBooking() {
      try {
        const response = await fetch(WORKER_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            timeslotId,
            name,
            company,
            email,
            message,
          }),
        })
        if (response.ok) {
          setRequestStatus(REQUEST_STATUS.SUCCESS);
          Cookies.set('did_book', 'true', { expires: 45 })
        } else {
          throw new Error('Error booking a meeting. HTTP status: ', response.status);
        }
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
        setRequestStatus(REQUEST_STATUS.INITIAL);
        setError('There was an error booking your meeting');
      }
    }
    sendBooking();
  };
  let groupedTimeslots = [];
  if (props.timeslots.length > 0) {
    for (const timeslot of props.timeslots) {
      if (groupedTimeslots[timeslot.dayGroup - 1] && groupedTimeslots[timeslot.dayGroup - 1].length > 0) {
        groupedTimeslots[timeslot.dayGroup - 1].push(timeslot);
      } else {
        groupedTimeslots[timeslot.dayGroup - 1] = [timeslot];
      }
    }
  }
  if (requestStatus === REQUEST_STATUS.SUCCESS) {
    return (
      <div className='BookingForm success'>
        <h2>We will get in touch!</h2>
        <p>
          Your appointment was successfully booked. In few minutes your will receive a calendar invitation on your email.
        </p>
        <p>
          See you in Lisbon.
        </p>
        <img id="airplane" src={imgAirplane} alt="Airplane from game Air Racer" />
        <img id="lines" src={imgLines} alt="Airplane traces left behind" />
      </div >
    );
  }
  return (
    <div className={`BookingForm ${error ? 'has-error' : ''}`}>
      <h2>Pick a time slot</h2>
      <div className='date-picker'>
        <img style={{ marginRight: '5px' }} src={imgIcon} alt="Icon for picking a date" />
        <div>
          <select value={dateIndex} onChange={onSelectDay} id="day">
            {groupedTimeslots.map((groupedTimeslot, index) => {

              const dateFormatter = new Intl.DateTimeFormat(undefined, {
                month: 'long',
                day: '2-digit',
                timeZone: 'Europe/Lisbon'
              });
              const formattedDate = dateFormatter.format(new Date(groupedTimeslot[0]?.start));

              return (
                <option key={index} value={index} className='day-group'>
                  {formattedDate.toUpperCase()}
                </option>
              );
            })}
          </select>

          <select value={timeslotId} onChange={onSelectTime} id="time">

            {groupedTimeslots[dateIndex]?.map(timeslot => {
              const timeFormatter = new Intl.DateTimeFormat(undefined, {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
                timeZone: 'Europe/Lisbon'
              });

              const formmatedTimeStart = timeFormatter.format(new Date(timeslot.start));
              const formmatedTimeEnd = timeFormatter.format(new Date(timeslot.end));
              const isDisabled = timeslot.remainingBookings === 0;

              return (
                <option key={timeslot.id} disabled={isDisabled} value={timeslot.id}>
                  {formmatedTimeStart + ' - ' + formmatedTimeEnd}
                </option>
              );
            })}

          </select>
          <div className="line" />
        </div>
      </div>
      <span className='note'><strong>Note:</strong> time slots are in local time</span>
      <p className='error'>{timeslotError}</p>


      <h2>Contact information</h2>
      <div className={`form-group ${focus.name || name.length > 0 ? 'focused' : ''}`}>
        <label onClick={() => onFocus('name', true)} className="form-label" htmlFor="name">Name</label>
        <input id='name' onBlur={() => onBlur('name')} onFocus={() => onFocus('name')} className="form-input" type="text" value={name} onChange={(e) => setName(e.target.value)} />
        <div className="line" />
      </div>

      <div className={`form-group ${focus.company || company.length > 0 ? 'focused' : ''}`}>
        <label className="form-label" htmlFor="company">Company name</label>
        <input id='company' onBlur={() => onBlur('company')} onFocus={() => onFocus('company')} className="form-input" type="text" value={company} onChange={(e) => setCompany(e.target.value)} />
        <div className="line" />
      </div>

      <div className={`form-group ${focus.email || email.length > 0 ? 'focused' : ''}`}>
        <label className="form-label" htmlFor="email">Email</label>
        <input id='email' onBlur={() => onBlur('email')} onFocus={() => onFocus('email')} className="form-input" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <div className="line" />
      </div>

      <div className={`form-group ${focus.message || message.length > 0 ? 'focused' : ''}`}>
        <label className="form-label" htmlFor="message">Message (optional)</label>
        <textarea id='message' onBlur={() => onBlur('message')} onFocus={() => onFocus('message')} className="form-input" value={message} onChange={(e) => setMessage(e.target.value)} />
        <div className="line" />
      </div>
      <button disabled={(requestStatus !== REQUEST_STATUS.INITIAL) || error || timeslotError} className={`${requestStatus === REQUEST_STATUS.LOADING ? 'loading' : ''}`} onClick={handleSubmit}>{requestStatus === REQUEST_STATUS.LOADING ? <span className='spinner' /> : 'SEND'}</button>
      <p id="error-message" className='error'>{error}</p>

    </div >
  );
};

export default BookingForm;
