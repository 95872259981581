

// Make requests to: https://server.gamanza-games.workers.dev/

import { useEffect, useState } from "react";
import BookingForm from "./BookingForm";
import { WORKER_URL } from "../config";
import Heading from "./Heading";
import Paragraph from "./Paragraph";
import Banner from "./Banner";
import Dots from "./Dots";
import * as Sentry from "@sentry/react";


function App() {
  const [timeslots, setTimeslots] = useState([]);

  useEffect(() => {
    async function fetchTimeslots() {
      try {
        const response = await fetch(WORKER_URL);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setTimeslots(data);

      } catch (error) {
        // Handle any errors
        Sentry.captureException(error);
        console.error('Fetch error:', error);
      }
    }
    fetchTimeslots();
  }, []);
  function onError(timeslotId) {

    setTimeslots(prevState => {
      const timeslots = [];
      for (const item of prevState) {
        if (item.id === timeslotId) {
          item.remainingBookings = 0;
        }
        timeslots.push(item);
      }
      return timeslots
    })
  }

  return (
    <div className="App">
      <Dots />
      <Heading />
      <div className="container">
        <div>
          <Paragraph />
          <Banner />
        </div>
        <BookingForm timeslots={timeslots} onError={onError} />
      </div>
    </div>
  );
}

export default App;
