
function Paragraph() {
    return (
        <div className="Paragraph">
            <p>Book an appointment and let our team give you a tour of our exciting arcade games portfolio at<span> SBC Summit in Lisbon</span></p>
        </div>
    );
}

export default Paragraph;
